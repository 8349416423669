import auth from "utils/auth"

export const HEADERS = ["Nome/CPF", "Data da proposta", "Status"]

export const ITEMS = [
  {
    title: "Propostas",
    icon: "article",
    type: "full-link",
    color: "grey-darkest-mobile",
    link: ROUTES.microloansProposals.path,
    hidden: "desktop",
  },
  {
    title: "Clientes Potenciais",
    type: "full-link",
    icon: "phone-book",
    color: "grey-darkest-mobile",
    profiles: [
      {
        profile: "master",
        type: "full-link",
        link: ROUTES.microloansPotentialCustomersCompanies.path,
      },
      {
        profile: "admin",
        type: "full-link",
        link: `${ROUTES.microloansPotentialCustomersCompany.path}?group_id=${
          auth.getUser()?.group_id
        }`,
      },
      {
        profile: "salesman",
        type: "full-link",
        link: ROUTES.microloansPotentialCustomers.path,
      },
    ],
  },
  {
    title: "Carteiras",
    icon: "contract-person",
    type: "full-link",
    link: ROUTES.microloansWalletActives.path,
    hidden: "desktop",
    color: "grey-darkest-mobile",
  },
  {
    title: "Remuneração",
    icon: "remuneration",
    type: "full-link",
    color: "grey-darkest-mobile",
    link: ROUTES.microloansRemuneration.path,
    hidden: "desktop",
    permission: {
      subject: "itiMicroloans",
      action: "remuneration",
    },
  },
  {
    title: "Nova Proposta",
    icon: "plus",
    type: "iframe",
    color: "grey-darkest-mobile",
    iframe_info: { business_id: "2", title: "Microcrédito" },
    permission: {
      subject: "itiMicroloans",
      action: "sell",
    },
  },
]
